<template>
	<div>
		<!-- <title-icon :title="$t('관제점 로그')" icon="fa-info-circle" :col="6">
      <div class="form-inline">
        <div class="btn-group m-r-20">
          <button class="btn btn-white" :class="[tableInfo.isShow ? 'active' : '']" v-for="(tableInfo, i) in tableInfos" :key="'tbl' + i" @click="tableInfo.isShow = !tableInfo.isShow">
            {{ tableInfo.tableType }}
          </button>
        </div>
      </div>
    </title-icon> -->
		<div class="d-flex flex-row">
			<div class="col-lg-9 p-l-0 p-r-0">
				<div class="pull-left">
					<div class="h5">{{ $t("관제점 구성 관리") }} > {{ $t("관제점 데이터 조회") }}</div>
				</div>
			</div>
			<div class="btn-group m-l-auto">
				<button class="btn btn-white" :class="[tableInfo.isShow ? 'active' : '']" v-for="(tableInfo, i) in tableInfos" :key="'tbl' + i" @click="tableInfo.isShow = !tableInfo.isShow">
					{{ $t(tableInfo.title) }}
				</button>
			</div>
		</div>
		<hr style="margin: 10px 0px 10px 0px" />
		<div class="row">
			<div class="col-9">
				<div class="form-inline m-b-10">
					<ln-picker :label="$t('일자') + ':'" v-model="sDate" :setting="{ format: 'YYYY-MM-DD' }" />
					<ln-picker :label="$t('시간') + ':'" v-model="sTime" :setting="{ format: 'HH:mm' }" />
					<ln-picker label="~" v-model="eTime" :setting="{ format: 'HH:mm' }" />
					<div class="form-group">
						<label> &nbsp; </label>
						<!-- <point-select v-model="ptAddr" :initVal="ptAddr" :useIdx="false" :useName="true" width="450px" /> -->
						<point-log-search v-model="ptAddr" :initVal="ptAddr" :useIdx="false" :useName="true" width="450px" />
					</div>

					<b-dropdown split :text="$t('조회')" variant="primary" @click="onSearch()" :disabled="isEmpty(ptAddr)">
						<b-dropdown-item style="width:215px;">
							<div class="form-group ">
								<button class="btn btn-default btn-sm" @click.stop="onSetCurrentDate">{{ $t("현재시간부터") }}</button>
							</div>
							<div class="form-group m-t-10">
								<button class="btn btn-default btn-sm" @click.stop="onSetAllDay">{{ $t("0시부터 23시까지로") }}</button>
							</div>
							<div class="form-group m-t-10">
								<button class="btn btn-default btn-sm" @click.stop="isSimple = !isSimple">{{ isSimple ? $t("간략보기") : $t("전체보기") }}</button>

								<button class="btn btn-default btn-sm m-l-5" @click.stop="isQuery = !isQuery">{{ isQuery ? $t("쿼리보기") : $t("쿼리숨김") }}</button>
							</div>
							<div class="form-group m-t-10">
								<table>
									<tr>
										<td><trans> 소수점 </trans>:</td>
										<td>{{ fixed }}</td>
										<td><button class="btn btn-default btn-sm" @click.stop="fixed > 0 ? (fixed -= 1) : ''">&lt;</button></td>
										<td><button class="btn btn-default btn-sm" @click.stop="fixed < 9 ? (fixed += 1) : ''">&gt;</button></td>
									</tr>
									<tr>
										<td><trans> 최대 건수 </trans>:</td>
										<td>{{ max }}</td>
										<td><button class="btn btn-default btn-sm" @click.stop="max > 1000 ? (max -= 1000) : ''">&lt;</button></td>
										<td><button class="btn btn-default btn-sm" @click.stop="max < 9999 ? (max += 1000) : ''">&gt;</button></td>
									</tr>
								</table>
							</div>
							<div class="form-group m-t-10">
								<label><trans> 통계 </trans>: </label>
								<select class="form-control m-l-10" v-model="aggType" @click.stop="">
									<option value="hide" selected> {{ $t("숨기기") }} </option>
									<option value="15min"> {{ $t("15분") }} </option>
								</select>
							</div>

							<div class="form-group m-t-10"></div>
						</b-dropdown-item>
					</b-dropdown>
				</div>
			</div>
		</div>
		<!-- Log Table 부분 -->
		<div class="row">
			<div class="col-md-6 row">
				<div v-for="tableInfo in tableInfos.filter((v) => v.searchType >= 6)" :key="'aLine' + tableInfo.searchType" :class="['col-md-6']">
					<point-log :ptAddr="ptAddr" :tableInfo="tableInfo" :max="max" @refresh="onSearch" @action="openSamplingModal" :isSimple="isSimple" :isQuery="isQuery" />
				</div>
			</div>
			<div class="col-md-6 row">
				<div v-for="tableInfo in tableInfos.filter((v) => v.searchType < 6)" :key="'bLine' + tableInfo.searchType" :class="['col-md-6']">
					<point-log :ptAddr="ptAddr" :tableInfo="tableInfo" :max="max" @refresh="onSearch" @action="openSamplingModal" :isSimple="isSimple" :isQuery="isQuery" />
				</div>
			</div>
		</div>

		<!-- TODO: 한줄로 길게 표시하는게 낫지 않나???
    <div class="row">
      <div v-for="tableInfo in tableInfos" :key="'aLine'+tableInfo.searchType" :class="['col-md-3', ]">
        <point-log :ptAddr="ptAddr" :tableInfo="tableInfo" :max="max" @refresh="onSearch" @action="openSamplingModal" :isSimple="isSimple" />
      </div>
    </div> -->

		<sampling-popup ref="samplingModal" :options="samplingOpt" />
	</div>
</template>

<style>
	.form-control[readonly] {
		background: #fff !important;
		color: #2d353c !important;
		opacity: 1 !important;
	}
</style>

<script>
	import backEndApi from "@api/backEndApi";
	import PointLog from "./components/PointLog.vue";
	import SamplingPopup from "./components/SamplingPopup.vue";
	import PointLogSearch from "./PointLogSearch.vue";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	import xelib from "xelib";
	function Point(pt) {
		let that = this;
		const { isEmpty, is } = global.xe;
		const { Unit } = xelib.PointBoxV2;

		if (isEmpty(pt)) throw `Point정보가 제공되지 않았습니다.`;
		Object.assign(this, pt);

		that.unit = (org) => {
			if (isEmpty(org)) return { ptVal: org };
			if (isEmpty(pt.rawUnit)) return { ptVal: org };
			if (isEmpty(pt.unit)) return { ptVal: org };

			let unitVal = Unit.convert(org, pt.rawUnit, pt.unit);

			return { ptVal: unitVal, applyUnit: org != unitVal ? `단위환산:  ${org}값을   ${pt.rawUnit} -> ${pt.unit} 변환하였습니다.. 결과=${unitVal}` : "" };
		};

		that.filter = (org) => {
			if (isEmpty(org)) return { ptVal: org };

			if (pt.min && org < pt.min) return { ptVal: pt.overflow, applyFilter: `필터처리:  ${org}값이 min값 ${pt.min}를 벗어났습니다. "${pt.overflow}"값으로 대체합니다.` };
			if (pt.max && org > pt.max) return { ptVal: pt.overflow, applyFilter: `필터처리:  ${org}값이 max값 ${pt.max}를 벗어났습니다. "${pt.overflow}"값으로 대체합니다.` };

			return { ptVal: org };
		};

		that.apply = function(item) {
			if (isEmpty(item.ptVal)) return item; // null 검사

			let unitRtn = that.unit(parseFloat(item.ptVal));
			item.ptVal = unitRtn.ptVal;
			item.applyUnit = unitRtn.applyUnit;

			if (isEmpty(item.ptVal)) return item; // null 검사

			let filterRtn = that.filter(item.ptVal);
			item.ptVal = filterRtn.ptVal;
			item.applyFilter = filterRtn.applyFilter;

			return item;
		};

		// 원본로그를 1분로그로, 1분을 15분으로, 15분을 1시간으로 만들어줌.
		that.makeSummary = function(logs, timeType) {
			if (isEmpty(logs)) return logs;
			if (logs.length == 0) return logs;

			// 날짜 구간의 구한다.
			let dts = logs.map((v) => v.regDt);

			let { ranges } = global.xe.PtLib.makeRanges(timeType, dts.min(), dts.max());

			// 배열내 시간 유형범위별로 쪼개서, unit, filter를 적용한다.
			return ranges.map((targetDt) => {
				let { longS, longE, dateFmt } = global.xe.PtLib.getTimeType(timeType, targetDt); // 현재 15분 구간의 시간범위를 구함.

				let rangeData = logs.filter((v) => v.regDt.format("yyyyMMddHHmmss") >= longS && v.regDt.format("yyyyMMddHHmmss") <= longE); // 시간범위내의 데이터를 찾음

				rangeData = rangeData.map((v) => that.apply(v));

				let aggData = that.agg(rangeData, `${longS.format(dateFmt)}`);

				return that.apply(aggData);
			});
		};

		that.defaultVal = that.ptType == "LST" ? 0 : null;

		that.agg = function(logs, regDt) {
			if (isEmpty(logs)) return { regDt, ptVal: that.defaultVal, ptOrg: null, ptPre: null };
			if (logs.length == 0) return { regDt, ptVal: that.defaultVal, ptOrg: null, ptPre: null };

			let vals = logs.map((v) => is(v.ptVal, 0)); // 합산전에 isNull 적용

			let val = null;
			switch (that.ptType) {
				case "LST":
					val = vals.first();
					break; // 역sort되어 있기 때문에, first가 last임.
				case "SUM":
					val = vals.reduce((acc, cur) => acc + cur, 0);
					break;
				case "AVG":
					val = vals.reduce((acc, cur) => acc + cur, 0) / vals.length;
					break;
				case "MAX":
					val = vals.reduce((acc, cur) => (acc > cur ? acc : cur), vals.first());
					break;
			}
			return { regDt, ptVal: val, ptOrg: null, ptPre: null };
		};
	}

	export default {
		components: {
			PointLog,
			SamplingPopup,
			PointLogSearch,
		},
		data() {
			return {
				useLive: true,

				sDate: localStorage._pointLog_sDate || new Date().format("yyyy-MM-dd"),
				sTime: localStorage._pointLog_sTime || "00:00",
				eTime: localStorage._pointLog_eTime || "23:59",

				max: 6000, // 최대 3000개 제한..

				// 표시 테이블 항목
				tableInfos: [
					{
						tableType: "TotalLog",
						title: global.xe.$t("총 로그"),
						searchType: 7,
						isShow: true,
						logData: [],
						dtFormat: "HH:mm:ss",
						sDt: "",
						eDt: "",
					},
					{
						tableType: "1Min",
						title: `1${global.xe.$t("분")}`,
						searchType: 6,
						isShow: true,
						logData: [],
						dtFormat: "HH:mm",
						sDt: "",
						eDt: "",
					},
					{
						tableType: "15Min",
						title: `15${global.xe.$t("분")}`,
						searchType: 5,
						isShow: true,
						logData: [],
						dtFormat: "HH:mm",
						sDt: "",
						eDt: "",
					},
					{
						tableType: "Hour",
						title: global.xe.$t("시"),
						searchType: 4,
						isShow: true,
						logData: [],
						dtFormat: "yyyy-MM-dd HH",
						sDt: "",
						eDt: "",
					},
					{
						tableType: "Day",
						title: global.xe.$t("일"),
						searchType: 3,
						isShow: true,
						logData: [],
						dtFormat: "yyyy-MM-dd",
						sDt: "",
						eDt: "",
					},
					{
						tableType: "Month",
						title: global.xe.$t("월"),
						searchType: 2,
						isShow: true,
						logData: [],
						dtFormat: "yyyy-MM",
						sDt: "",
						eDt: "",
					},
					// {
					//   tableType: "Year",
					//   searchType: 1,
					//   isShow: true,
					//   logData: [],
					//   dtFormat: 'yyyy',
					//   sDt:"", eDt:""
					// },
				],

				ptAddr: localStorage._pointLog_ptAddr || "",
				currentValueChange: false, // 현재값 변경여부
				currentDateChange: false, // 현재값 갱신시간 변경여부
				currentRegDt: "", // 현재값 갱신시간
				currentIsOrg: false, // 현재값 Org or Val
				currentValue: null, // 현재값
				currentValues: [], // 현재값 목록

				to_real: null,

				fixed: 3, //소수점 자리수
				aggType: "hide",

				samplingOpt: { timeType: 7, sDt: "", eDt: "", points: "" },

				liveAM: this.readStorage("liveAM"),

				isSimple: false,
				isQuery: false,
			};
		},
		computed: {
			liveValue() {
				let liveReal = this.readStorage("liveReal");

				let tr = liveReal[this.ptAddr];
				if (!this.isEmpty(tr)) return this.currentIsOrg ? tr.ptOrg : tr.ptVal;

				return "";
			},
			// 보기를 원하는 테이블만 골라 화면에 생성한다.
			showTables() {
				return this.tableInfos.filter((v) => v.isShow);
			},
		},
		watch: {
			sDate(newV) {
				localStorage._pointLog_sDate = newV;
			},
			sTime(newV) {
				localStorage._pointLog_sTime = newV;
			},
			eTime(newV) {
				localStorage._pointLog_eTime = newV;
				if (newV < this.sTime) {
					this.alertNoti(popupMessages.POINT_SMALLER_END_DATE_POPUP_MESSAGE);
				}
			},
			ptAddr(newV, oldV) {
				localStorage._pointLog_ptAddr = newV;

				if (this.isEmpty(this.ptAddr) || newV !== oldV) {
					this.initData();
				}
			},
			currentRegDt(newV) {
				if (!this.isEmpty(newV)) this.$refs.tooltip.$emit("open"); // 툴팁표시
			},
		},
		beforeRouteLeave(to, from, next) {
			clearInterval(this.to_real);
			next();
		},
		beforeDestroy() {
			clearInterval(this.to_real);
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.$store.dispatch("UPDATE_PT_MAP");

				this.liveAM = this.readStorage("liveAM");
			},
			initData(target) {
				clearInterval(this.to_real);
				this.to_real = null;

				this.currentValueChange = false;
				this.currentDateChange = false;
				this.currentRegDt = "";
				this.currentValue = null; // totalReal  ptOrg값..
				this.currentValues.clear();

				this.showTables.map((table) => {
					if (!this.isEmpty(target) && target.searchType !== table.searchType) return;

					table.sDt = table.eDt = "";
					table.logData.clear();
				});
			},
			// 전체 조회
			onSearch(target) {
				this.initData(target);

				let sDt = (this.sDate.format("yyyy-MM-dd") + this.sTime + ":00").normalize(7);
				let eDt = (this.sDate.format("yyyy-MM-dd") + this.eTime + ":59").normalize(7);

				// 실시간 조회 - 반복
				if (!this.useLive) this.onRefreshReal();

				let ptIdx = this.liveAM[this.ptAddr].ptIdx;

				// 그리드 조회
				// TODO: 로딩을 제거하는 타임아웃이 있어야 함...
				this.showTables.map((table) => {
					this.startSearch(table, target, sDt, eDt, ptIdx);
				});

				this.searchTotalReal(this.ptAddr);
			},
			startSearch(table, target, sDt, eDt, ptIdx) {
				if (!this.isEmpty(target) && target.searchType !== table.searchType) return;

				if (table.searchType == 1) {
					// 월
					//table.sDt = this.sDate.format('yyyy');
					table.sDt = this.sDate.format("yyyy");
					table.eDt = this.sDate.format("yyyy");
				} else if (table.searchType == 2) {
					// 월
					//table.sDt = this.sDate.format('yyyy') + "-01-01 00:00:00";
					table.sDt = this.sDate.format("yyyy") + "-01-01 00:00:00";
					table.eDt = this.sDate.format("yyyy") + "-12-31 23:59:59";
				} else if (table.searchType == 3) {
					// 일
					//table.sDt = this.sDate.format('yyyy') + `${this.sDate.parseDate().getMonth().pad(2,'0')}-01 00:00:00`;
					table.sDt = this.sDate.format("yyyy-MM") + "-01 00:00:00";
					table.eDt = this.sDate.format("yyyy-MM") + `-${this.sDate.lastDate()} 23:59:59`;
				} else if (table.searchType == 4) {
					// 시
					table.sDt = this.sDate.format("yyyy-MM-dd ") + this.sTime.substring(0, 2) + ":00:00";
					table.eDt = this.sDate.format("yyyy-MM-dd ") + this.eTime.substring(0, 2) + ":59:59";
				} else {
					table.sDt = sDt;
					table.eDt = eDt;
				}

				table.loading = true;
				table.fixed = this.fixed;

				if (table.searchType == 7) return this.searchTotalLog(table, this.ptAddr);
				else if (table.searchType == 6) return this.searchSummary1Min(table, ptIdx);
				else return this.searchSummary(table, ptIdx);
			},
			aggregate15Min(table, data) {
				let arr = [];

				let ptc = new Point(this.liveAM[this.ptAddr]);

				let ptType = ptc.ptType; // table.sDt, table.eDt 구간의 15분단위 range를 구해서 루프를 돌아야 함.

				let { ranges } = global.xe.PtLib.makeRanges(5, table.sDt, table.eDt);

				ranges = ranges.sort((a, b) => (b > a ? 1 : -1)); // desc // 15분 시간 구간 합산때문에 역소트해야 함.

				ranges.map((targetDt) => {
					let { longS, longE } = global.xe.PtLib.getTimeType(5, targetDt); // 현재 15분 구간의 시간범위를 구함.

					let rangeData = data.filter((v) => v.regDt.format("yyyyMMddHHmmss") >= longS && v.regDt.format("yyyyMMddHHmmss") <= longE); // 시간범위내의 데이터를 찾음

					rangeData = rangeData.map((v) => ptc.apply(v)); // 원본로그

					let summary = ptc.makeSummary(rangeData, 6); // 1분 로그

					let aggData = ptc.agg(summary, `${ptType} ${longS.format("HH:mm")}`); // 15분 합산하려면, 1분로그에서 unit,filter를 적용하고 합산해야 함.

					arr.push(aggData);

					arr.append(rangeData);
				});

				return arr;
			},
			//#region  백엔드 실제 조회 함수
			// 원본 데이터
			searchTotalLog(table, ptAddr) {
				return backEndApi.totalLog.searchTotalLog(ptAddr, table.sDt, table.eDt, this.max).then(({ data }) => {
					table.loading = false;
					if (this.$err(data)) return;

					data = data.sort((a, b) => (b.regDt > a.regDt ? 1 : -1)); // desc - 그리드에 표시할 기본 형식이 역소트임.

					if (this.aggType == "15min") data = this.aggregate15Min(table, data);

					table.logData = data;
				});
			},
			// 1분 취합 데이터
			searchSummary1Min(table, ptIdx) {
				return backEndApi.summary.searchSummary1Min(ptIdx, table.sDt, table.eDt).then(({ data }) => {
					table.loading = false;
					if (this.$err(data)) return;

					data = data.sort((a, b) => (b.regDt > a.regDt ? 1 : -1)); // desc - 그리드에 표시할 기본 형식이 역소트임.

					if (this.aggType == "15min") data = this.aggregate15Min(table, data);

					table.logData = data;
				});
			},
			// 취합 데이터
			searchSummary(table, ptIdx) {
				return backEndApi.summary.searchSummaryByTimeUnit(ptIdx, table.searchType, table.sDt, table.eDt).then(({ data }) => {
					table.loading = false;
					if (this.$err(data)) return;

					data = data.sort((a, b) => (b.regDt > a.regDt ? 1 : -1)); // desc - 그리드에 표시할 기본 형식이 역소트임.

					table.logData = data;
				});
			},
			//#endregion

			// 실시간값
			onRefreshReal() {
				this.searchTotalReal(this.ptAddr);

				clearInterval(this.to_real);
				this.to_real = setInterval(this.searchTotalReal, 5000, this.ptAddr);
			},
			searchTotalReal(ptAddr) {
				if (this.isEmpty(ptAddr)) return;

				backEndApi.pointInfo.searchTotalReal(ptAddr).then(({ data }) => {
					if (this.$err(data)) return;

					if (!this.isEmpty(data)) {
						console.log(data);
						let curVal = this.currentIsOrg ? data.ptOrg : data.ptVal;

						this.currentValueChange = this.currentValue !== curVal;
						this.currentDateChange = this.currentRegDt !== data.regDt;
						this.currentRegDt = data.regDt;
						this.currentValue = curVal;
						if (this.currentValueChange) this.currentValues.push(data);
						if (this.currentValues.length > 30) this.currentValues.range(this.currentValues.splice(3));

						if (this.$refs.tooltip) this.$refs.tooltip.$emit("open");
					}
				});
			},
			openSamplingModal(tableInfo) {
				this.samplingOpt.timeType = parseInt(tableInfo.searchType);
				this.samplingOpt.sDt = (this.sDate.format("yyyy-MM-dd") + this.sTime + ":00").normalize(7);
				this.samplingOpt.eDt = (this.sDate.format("yyyy-MM-dd") + this.eTime + ":59").normalize(7);
				this.samplingOpt.points = this.ptAddr;

				this.$refs.samplingModal.show();
			},

			onSetCurrentDate() {
				let now = new Date();
				let hh = now.format("HH");
				let mm = now.format("mm");
				mm = mm[0] + (parseInt(mm[1]) > 5 ? "5" : "0");
				this.sDate = now.format("yyyy-MM-dd");
				this.sTime = now.format("HH:") + mm;
				this.eTime = hh >= "23" ? `${hh}:59` : `${(parseInt(hh) + 1).pad(2, "0")}:59`;
			},
			onSetAllDay() {
				this.sTime = "00:00";
				this.eTime = "23:59";
			},
		},
	};
</script>
